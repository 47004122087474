<template>
    <el-container class="content">
        <el-aside width="200px">
            <el-menu :default-active="activeIndex" class="menu" router @open="handleOpen" :default-openeds="defaultOpeneds" unique-opened>
                <el-submenu index="1" title>
                    <template slot="title">
                        <span>{{ $lang('tmdDOTco.00697', $route) }}</span>
                    </template>
                    <el-menu-item index="index">{{ $lang('tmdDOTco.00698', $route) }}</el-menu-item>
                    <el-menu-item index="info">{{ $lang('tmdDOTco.00699', $route) }}</el-menu-item>
                    <el-menu-item index="security">{{ $lang('tmdDOTco.00700', $route) }}</el-menu-item>
                    <el-menu-item index="delivery_address">{{ $lang('tmdDOTco.00701', $route) }}</el-menu-item>
                    <el-menu-item index="collection">{{ $lang('tmdDOTco.00702', $route) }}</el-menu-item>
                    <el-menu-item index="footprint">{{ $lang('tmdDOTco.00703', $route) }}</el-menu-item>
                </el-submenu>
                <el-submenu index="2" title>
                    <template slot="title">
                        <span>{{ $lang('tmdDOTco.00704', $route) }}</span>
                    </template>
                    <el-menu-item index="order_list">{{ $lang('tmdDOTco.00705', $route) }}</el-menu-item>
                    <el-menu-item index="activist">{{ $lang('tmdDOTco.00706', $route) }}</el-menu-item>
                    <template v-if="is_verify == 1">
                        <el-menu-item index="verification">{{ $lang('tmdDOTco.00707', $route) }}</el-menu-item>
                        <el-menu-item index="verification_list">{{ $lang('tmdDOTco.00708', $route) }}</el-menu-item>
                    </template>
                </el-submenu>
                <el-submenu index="3" title>
                    <template slot="title">
                        <span>{{ $lang('tmdDOTco.00709', $route) }}</span>
                    </template>
                    <el-menu-item index="account">{{ $lang('tmdDOTco.00710', $route) }}</el-menu-item>
                    <el-menu-item index="withdrawal">{{ $lang('tmdDOTco.00711', $route) }}</el-menu-item>
                    <el-menu-item index="my_coupon">{{ $lang('tmdDOTco.00712', $route) }}</el-menu-item>
                    <el-menu-item index="my_point">{{ $lang('tmdDOTco.00713', $route) }}</el-menu-item>
					<el-menu-item index="account_list">{{ $lang('tmdDOTco.00714', $route) }}</el-menu-item>
                    <!-- <el-menu-item index="level">会员等级</el-menu-item> -->
                </el-submenu>
            </el-menu>
        </el-aside>
        <el-main class="member">
            <transition name="slide"><router-view /></transition>
        </el-main>
    </el-container>
</template>
<script>
    import { checkisverifier } from "@/api/order/verification"
    export default {
        name: "home",
        components: {},
        data: () => {
            return {
                defaultOpeneds: ["1"],
                activeIndex: "index",
                is_verify: 1
            }
        },
        created() {
            this.activeIndex = this.$route.meta.parentRouter ||this.$route.path.replace("/member/", "")
            this.checkIsVerifier()
        },
        methods: {
            handleOpen(key, keyPath) {
                this.defaultOpeneds = keyPath
            },
            checkIsVerifier() {
                checkisverifier()
                    .then(res => {
                        if (res.data) {
                            this.is_verify = 1
                        }
                    })
                    .catch(err => {
                        this.is_verify = 0
                    })
            }
        },
        watch: {
            $route(curr) {
				this.activeIndex = curr.meta.parentRouter||this.$route.path.replace("/member/", "");
            }
        }
    }
</script>
<style lang="scss" scoped>
    .menu {
        min-height: 730px;
    }
    .content {
        display: flex !important;
        margin: 20px 0;
    }
    .member {
        margin-left: 15px;
    }
</style>
